import { FC, useState, useEffect } from 'react';

import {
  Dialog,
  StyledTrigger,
  BackButton,
  StyledNavigationMenuContentDesktop,
  StyledDialogContentMobile,
  StyledNavigationMenuTriger,
} from '@custom-components/MegaMenu';
import { useRouter } from '@dxp-next';
import { useLinkComponent } from '@link';
import { Placeholder, getIllustration } from '@sitecore/common';
import { MegaMenuDivisionRendering } from '@sitecore/types/manual/MegaMenuDivision';
import { Box, Grid, Stack, NavLink, Text, Divider, Stretch, PageGrid, Badge } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@sparky/icons';

interface MegaMenuDivisionComponent {
  fields: MegaMenuDivisionRendering['fields'];
  placeholders: MegaMenuDivisionRendering['placeholders'];
}

const MegaMenuDivision: FC<MegaMenuDivisionRendering> = props => {
  const isDesktop = useMediaQuery('lg');
  return isDesktop ? <Desktop {...props} /> : <Mobile {...props} />;
};

const Desktop: FC<MegaMenuDivisionComponent> = ({ fields, placeholders }) => {
  const Link = useLinkComponent();

  const hasCard = !!placeholders?.['jss-megamenu-division-cards']?.length;
  const isLink = fields?.link?.value?.href;

  return isLink ? (
    <Stretch>
      <Stack alignY="center">
        <Link href={fields.link.value.href} linkType={fields.link.value.linktype}>
          <NavLink>
            <Text size="BodyL">{fields?.title?.value}</Text>
          </NavLink>
        </Link>
      </Stack>
    </Stretch>
  ) : (
    <>
      <StyledNavigationMenuTriger key={fields.title.value}>
        {fields?.title?.value}
        <ChevronDownIcon color="iconOnBackgroundVarFive" />
      </StyledNavigationMenuTriger>
      <Stack>
        <StyledNavigationMenuContentDesktop>
          <Box paddingY="16" backgroundColor="backgroundPrimary">
            <PageGrid>
              <PageGrid.Item gridColumn="1/-1">
                <Grid gridTemplateColumns={hasCard || fields?.links?.length < 5 ? '2fr 1fr' : 'auto'}>
                  <Stack.Item>
                    <Grid
                      rowGap="10"
                      columnGap="6"
                      gridTemplateColumns={hasCard || fields?.links?.length < 5 ? '1fr 1fr' : '1fr 1fr 1fr'}
                      as="ul">
                      {fields?.links?.map(item => {
                        const Illustration = getIllustration(item.illustration.value);
                        return (
                          <Stack key={item?.link?.value?.id} as="li" direction="row" alignY="center" gap="3">
                            <Illustration size="small" color="iconSecondary" />
                            <Stack>
                              <Stack direction="row" gap={2}>
                                <Link
                                  href={item?.link?.value?.href}
                                  linkType={item?.link?.value?.linktype}
                                  currentMatch="exact">
                                  <NavLink>
                                    <Text size="BodyL">{item?.link?.value?.text}</Text>
                                  </NavLink>
                                </Link>
                                {item?.badge?.value && (
                                  <Stack.Item>
                                    <Badge size="S">{item.badge.value}</Badge>
                                  </Stack.Item>
                                )}
                              </Stack>
                              <Text color="textLowEmphasis" weight="regular" size="BodyS">
                                {item?.teaserText?.value}
                              </Text>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Grid>
                  </Stack.Item>
                  {hasCard ? (
                    <Grid.Item gridColumn="2" gridRow="1/-1">
                      <Placeholder name="jss-megamenu-division-cards" />
                    </Grid.Item>
                  ) : null}
                </Grid>
              </PageGrid.Item>
            </PageGrid>
          </Box>
        </StyledNavigationMenuContentDesktop>
      </Stack>
    </>
  );
};

const Mobile: FC<MegaMenuDivisionComponent> = ({ fields, placeholders }) => {
  const [isOpen, setOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => setOpen(false);

    router.events.on('beforeHistoryChange', handleRouteChange);
    return () => router.events.off('beforeHistoryChange', handleRouteChange);
  }, [router]);

  const hasCard = !!placeholders?.['jss-megamenu-division-cards']?.length;
  const isLink = fields?.link?.value?.href;

  const Link = useLinkComponent();

  return isLink ? (
    <Stack gap="7">
      <Link href={fields.link.value.href} linkType={fields.link.value.linktype}>
        <NavLink>
          <Text size="BodyL">{fields?.title?.value}</Text>
        </NavLink>
      </Link>
      <Divider />
    </Stack>
  ) : (
    <Stack alignX="justify" gap="7" alignY="center">
      <Dialog.Root modal={false} open={isOpen}>
        <StyledTrigger onClick={() => setOpen(prevState => !prevState)}>
          {fields?.title?.value}
          <ChevronRightIcon />
        </StyledTrigger>
        <Divider />
        <StyledDialogContentMobile>
          <Stretch>
            <Box overflow="auto" paddingY="5" paddingX={{ initial: '5', sm: '10' }} backgroundColor="backgroundPrimary">
              <Stack gap="6">
                <BackButton onClick={() => setOpen(prevState => !prevState)}>
                  <ChevronLeftIcon color="currentColor" />
                  {fields?.title?.value}
                </BackButton>
                <Divider />
                <Stack gap="6" direction={{ md: 'row' }}>
                  <Stack.Item grow>
                    <Grid
                      as="ul"
                      rowGap="10"
                      columnGap="6"
                      gridTemplateColumns={{ initial: '1fr', md: hasCard ? '1fr' : '1fr 1fr' }}>
                      {fields?.links?.map(item => {
                        const Illustration = getIllustration(item?.illustration?.value);
                        return (
                          <Grid.Item key={item?.link?.value?.id} as="li">
                            <Stack direction="row" alignY="center" gap="3">
                              <Illustration size="small" color="iconSecondary" />
                              <Stack>
                                <Stack direction="row" gap={2}>
                                  <Link
                                    href={item?.link?.value?.href}
                                    linkType={item?.link?.value?.linktype}
                                    currentMatch="exact">
                                    <NavLink>
                                      <Text size="BodyL">{item?.link?.value?.text}</Text>
                                    </NavLink>
                                  </Link>
                                  {item?.badge?.value && (
                                    <Stack.Item>
                                      <Badge size="S">{item.badge.value}</Badge>
                                    </Stack.Item>
                                  )}
                                </Stack>
                                <Text color="textLowEmphasis" weight="regular" size="BodyS" aria-hidden>
                                  {item?.teaserText?.value}
                                </Text>
                              </Stack>
                            </Stack>
                          </Grid.Item>
                        );
                      })}
                    </Grid>
                  </Stack.Item>
                  {hasCard ? (
                    <Stack.Item grow>
                      <Placeholder name="jss-megamenu-division-cards" />
                    </Stack.Item>
                  ) : null}
                </Stack>
              </Stack>
            </Box>
          </Stretch>
        </StyledDialogContentMobile>
      </Dialog.Root>
    </Stack>
  );
};

export default MegaMenuDivision;
