import * as Dialog from '@radix-ui/react-dialog';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { Icon } from '@sparky/icons';
import { styled } from '@sparky/stitches';
export { Dialog };
export { NavigationMenu };

export const StyledNavigationMenuContentDesktop = styled(NavigationMenu.Content, {
  position: 'absolute',
  right: 0,
  left: 0,
  marginTop: '$1',
  boxShadow: '$m',
  zIndex: 2,
});

export const StyledDialogContentMobile = styled(Dialog.Content, {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export const StyledTrigger = styled(Dialog.Trigger, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$2',

  cursor: 'pointer',
  background: 'none',
  border: 'none',
  paddingX: 0,

  typography: '$bodyL',
  fontWeight: '$bodyBold',
  color: '$colors$linkPrimary',

  '&:hover, &:active': {
    color: '$colors$linkBrand',
  },
  [`&:hover ${Icon}`]: {
    fill: '$iconBrand',
  },
});

export const StyledNavigationMenuTriger = styled(NavigationMenu.Trigger, StyledTrigger, {
  '&[data-state="open"]': {
    color: '$colors$linkBrand',
    [`${Icon}`]: {
      fill: '$iconBrand',
      transform: 'rotate(180deg)',
    },
  },
});

export const BackButton = styled('button', {
  display: 'flex',
  gap: '$8',
  alignItems: 'center',
  color: '$colors$linkBrand',
  typography: '$bodyL',
  fontWeight: '$bodyBold',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  '&:hover, &:active': {
    color: '$colors$linkPrimary',
  },
});

export const NavigationMenuWrapper = styled('div', {
  /*  Resets positioning set on a div that's automatically added by Radix when using the NavigationMenu.List */
  '& nav > div': {
    position: 'initial !important',
  },
});
